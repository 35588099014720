import React from 'react';
import { styled } from '../../StyledComponents';

import { TypographyProps, typographyHelper } from './utils/typographyHelper';
import {
  SpacingTypographyProps,
  spacingTypographyHelper,
} from './utils/spacingTypographyHelper';

export interface CaptionProps extends TypographyProps, SpacingTypographyProps {
  className?: string;
  display?: 'block' | 'inline';
  size?: 'S' | 'M' | 'L' | 'XL';
}

export const Caption: React.FC<React.PropsWithChildren<CaptionProps>> = ({
  size = 'M',
  ...props
}) => <Root size={size} {...props} />;

const RootComponent: React.FC<React.PropsWithChildren<CaptionProps>> = (
  props
) => {
  const { className, children } = props;
  return <span className={className}>{children}</span>;
};

const RootWithTheme = styled(RootComponent)(({ theme, size }) => {
  const captionLabelStyle = theme.typography.caption[size as 'M'];

  return {
    ...theme.typography.caption.default,
    ...captionLabelStyle,
    color: 'currentColor',
  };
});

const Root = styled(RootWithTheme)`
  ${typographyHelper}
  ${spacingTypographyHelper}
  display: ${({ display = 'block' }) => display};
`;
